import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Admin/Login/Login";

import Dashboard from "./Components/Admin/DashBoard/DashBoard";
import Sidebar from "./Components/Admin/Sidebar/Sidebar";
import Header from "./Components/Admin/Header/Header";
import AdvanceDashboard from "./Components/Admin/DashBoard/AdvanceDashboard/AdvanceDashboard";
import PrivateDashboard from "./Components/Admin/DashBoard/PrivateDashboard/PrivateDashboard";
import Order from "./Components/Admin/DashBoard/Order/Order";

// Masters

// import PrimarySkills from "./Components/Admin/Masters/PrimarySkills/Tables";
// import SecondarySkills from "./Components/Admin/Masters/SecondarySkills/Tables";

// Talent Pool

// Recruiters

import Country from "./Components/Admin/Masters/Country/Tables";
import State from "./Components/Admin/Masters/State/Tables";
import City from "./Components/Admin/Masters/City/Tables";
import Users from "./Components/Admin/Masters/userDetails/Tables";
// import UsersDownload from "./Components/Admin/Masters/userDetails/Tables";

// Lead Dashborad

const App = () => {
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  return (
    <div className="main d-flex">
      {headerText === "/" || headerText === "/lead-dashboard" ? (
        <></>
      ) : (
        <Sidebar />
      )}

      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/Header" element={<Header />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/advanceDashboard" element={<AdvanceDashboard />} />
        {/* Masters Start */}

        {/*Country */}
        <Route path="/masters/country" element={<Country />} />

        {/*Satate */}
        <Route path="/masters/state" element={<State />} />

        {/*City */}
        <Route path="/masters/city" element={<City />} />

        {/*City */}
        <Route path="/masters/users" element={<Users />} />

        {/*City */}
        {/* <Route path="/masters/users/downloadu-user/:id" element={<UsersDownload />} /> */}

        {/*Zone */}
      </Routes>
    </div>
  );
};

export default App;
