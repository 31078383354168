import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import SideBarIMG from "../../../sidebarimage/firstImage.png";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../../utils/context";
library.add(fas);

const Sidebar = () => {
  const { IMG_URL } = useContext(Context);
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);
  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]);

  const [active, setActive] = useState("0");

  const [activeAccordionItem, setActiveAccordionItem] = useState(null);
  const [activeInnerListItem, setActiveInnerListItem] = useState(null);

  const handleAccordionClick = (itemKey) => {
    setActiveAccordionItem(itemKey === activeAccordionItem ? null : itemKey);
  };

  const handleInnerListItemClick = (itemId) => {
    setActiveInnerListItem(itemId === activeInnerListItem ? null : itemId);
  };

  return (
    <>
      <section style={{ width: isOpen ? "250px" : "50px" }} className="sidebar">
        <div className="top_section">
          {isOpen && (
            <Link
              to="/masters/users"
              className="link-body-emphasis d-inline-flex text-decoration-none rounded"
            >
              <div className="log-background">
                <img className="logo ms-2" src={SideBarIMG} />
              </div>
            </Link>
          )}
          <div
            style={{ marginLeft: isOpen ? "50px" : "22px" }}
            className="bars"
          >
            <FaBars onClick={() => setIsOpen(!isOpen)} />
          </div>
        </div>

        <ul className="list-unstyled ps-0">
          <Accordion defaultActiveKey={active}>
            {/* <Accordion.Item
              eventKey="1"
              className={`mb-1 ${activeAccordionItem === "1" ? "active" : ""}`}
              onClick={() => handleAccordionClick("1")}
            >
              <Accordion.Header>
                <div className="text-clr me-3">
                  <FontAwesomeIcon icon="fa-solid fa-house" />
                </div>
                <p
                  onClick={() => setIsOpen(!isOpen)}
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Dashboard
                </p>
              </Accordion.Header>
              <Accordion.Body style={{ display: isOpen ? "block" : "none" }}>
                <ul className=" list-unstyled fw-normal pb-1 small">
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/privateDashboard")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("privateDashboard")}
                  >
                    <Link
                      to="/privateDashboard"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded "
                    >
                      <p
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Private Dashboard
                      </p>
                    </Link>
                  </li>
                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/advanceDashboard")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("advanceDashboard")}
                  >
                    <Link
                      to="/advanceDashboard"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Advance Dashboard
                      </p>
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> */}

            {/* ****************************************************************Settings ******************************************* */}

            <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="2"
              className={`mb-1 ${activeAccordionItem === "2" ? "active" : ""}`}
              onClick={() => handleAccordionClick("2")}
            >
              <Accordion.Header>
                <div className="text-clr  me-3">
                  <FontAwesomeIcon icon="fa-solid fa-gear" />
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Settings
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {/* <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/country")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("country")}
                  >
                    <Link
                      to="/masters/country"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Country
                      </p>
                    </Link>
                  </li> */}

                  {/* State */}

                  {/* <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/state")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("state")}
                  >
                    <Link
                      to="/masters/state"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        State
                      </p>
                    </Link>
                  </li> */}

                  {/* City */}

                  {/* <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/city")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("city")}
                  >
                    <Link
                      to="/masters/city"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        City
                      </p>
                    </Link>
                  </li> */}

                  <li
                    className={`${
                      isOpen
                        ? headerText.includes("/masters/users")
                          ? "active block"
                          : "none"
                        : "none"
                    }`}
                    onClick={() => handleInnerListItemClick("users")}
                  >
                    <Link
                      to="/masters/users"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Users
                      </p>
                    </Link>
                  </li>
                </ul>
                {/* </div> */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </ul>
      </section>
    </>
  );
};

export default Sidebar;
