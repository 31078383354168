import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../../common/Button";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
library.add(fas);

const EditOffCanvance = (props) => {
  const { postData, getData, IMG_URL } = useContext(Context);
  const [errors, setErrors] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const id = props.show;

  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    position: "",
    email: "",
    mobile_number: "",
    address: "",
    image: null,
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };
  const handleChange = (e) => {
    if (e.target.type === "file") {
      const selectedImage = e.target.files[0];

      // Check if a file is selected
      if (selectedImage) {
        // Generate image preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(selectedImage);

        // Update formData with the selected image
        setFormData({ ...formData, image: selectedImage });
      } else {
        // User canceled image selection, clear the image preview and formData
        setImagePreview(null);
        setFormData({ ...formData, image: null });
      }
    } else {
      // Handle text input
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = Validation.FormValidation({
  //     classname: ".userDetailsclass",
  //   });

  //   if (validationErrors) {
  //     try {
  //       const response = await postData(`/masters/users/${id}`, formData);

  //       if (response.success) {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       } else {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error submitting form:", error);
  //     }
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = validateForm();

  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //   } else {
  //     try {
  //       const response = await postData(`/masters/users/${id}`, formData);
  //       if (response.success) {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       } else {
  //         setShowModal({ code: response.code, message: response.message });
  //         setTimeout(() => {
  //           setShowModal(0);
  //           props.handleClose();
  //         }, 1000);
  //       }
  //       await setErrors();
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("last_name", formData.last_name);
        formDataToSend.append("position", formData.position);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("mobile_number", formData.mobile_number);
        formDataToSend.append("address", formData.address);
        formDataToSend.append("image", formData.image);
        formDataToSend.append("linkedin_url", formData.linkedin_url);

        const response = await postData(`/masters/users/${id}`, formDataToSend);

        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        }
        await setErrors();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    const regexAlpha = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    const regexEmail =
      /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const regexContact = /^[0-9]{10}$/;

    // Validate 'first_name' field
    if (!formData.name) {
      errors.name = "First Name is required";
    }
    // else if (!regexAlpha.test(formData.name.trim())) {
    //   errors.name = "Enter a valid First Name";
    // }

    // Validate 'last_name' field
    if (!formData.last_name) {
      errors.last_name = "Last Name is required";
    }
    // else if (!regexAlpha.test(formData.last_name.trim())) {
    //   errors.last_name = "Enter a valid Last Name";
    // }

    if (!formData.linkedin_url) {
      errors.linkedin_url = "Linked In URL is required";
    }

    // Validate 'position' field
    if (!formData.position) {
      errors.position = "Position is required";
    }
    // else if (!regexAlpha.test(formData.position.trim())) {
    //   errors.position = "Enter a valid Position";
    // }

    // Validate 'email' field
    if (!formData.email) {
      errors.email = "Email is required";
    }
    // else if (!regexEmail.test(formData.email.trim())) {
    //   errors.email = "Enter a valid Email";
    // }

    // Validate 'contact' field
    if (!formData.mobile_number) {
      errors.mobile_number = "T No is required";
    }

    // else if (!regexContact.test(formData.mobile_number.trim())) {
    //   errors.mobile_number = "Enter a valid Contact number (10 digits)";
    // }

    // Validate 'address' field
    if (!formData.address) {
      errors.address = "D No is required";
    }

    if (!formData.image) {
      errors.image = "image is required";
    }

    return errors;
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  const GetEditData = async () => {
    const response = await getData(`/masters/users/${id}`);
    setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Edit State</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit User</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="stateclass"
                          >
                            <Row>
                              {/* <Col
                                lg={8}
                                md={10}
                                className=" mx-auto Add-content"
                              >
                                <Row> */}

                              <Col md={6}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      First Name
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData?.name}
                                            onChange={handleChange}
                                            placeholder="First Name "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.name && (
                                          <span style={errorStyle}>
                                            {errors?.name}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Last Name
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="last_name"
                                            value={formData?.last_name}
                                            onChange={handleChange}
                                            placeholder="Last Name "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.last_name && (
                                          <span style={errorStyle}>
                                            {errors?.last_name}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Position
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="position"
                                            value={formData?.position}
                                            onChange={handleChange}
                                            placeholder="Position "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.position && (
                                          <span style={errorStyle}>
                                            {errors?.position}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Email
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="email"
                                            value={formData?.email}
                                            onChange={handleChange}
                                            placeholder="Email "
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.email && (
                                          <span style={errorStyle}>
                                            {errors?.email}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      T:
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="mobile_number"
                                            value={formData?.mobile_number}
                                            onChange={handleChange}
                                            placeholder="T No"
                                            id="inputEmail3"
                                            className="input-mandatory"
                                            // onInput={(e) => {
                                            //   // Remove non-numeric characters
                                            //   e.target.value =
                                            //     e.target.value.replace(
                                            //       /\D/g,
                                            //       ""
                                            //     );

                                            //   if (e.target.value.length > 10) {
                                            //     e.target.value =
                                            //       e.target.value.slice(0, 10);
                                            //   }
                                            // }}
                                          />
                                        </InputGroup>
                                        {errors?.mobile_number && (
                                          <span style={errorStyle}>
                                            {errors?.mobile_number}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      D:
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="address"
                                            value={formData?.address}
                                            onChange={handleChange}
                                            placeholder="D No"
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.address && (
                                          <span style={errorStyle}>
                                            {errors?.address}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Image
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="file"
                                            name="image"
                                            accept="image/*"
                                            onChange={handleChange}
                                          />
                                        </InputGroup>
                                        {errors?.image && (
                                          <span style={errorStyle}>
                                            {errors?.image}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    {/* Left side - Image */}
                                    <Col md={4}>
                                      {imagePreview ? (
                                        <img
                                          src={imagePreview}
                                          alt="Image Preview"
                                          className="table-image"
                                          style={{
                                            height: "100px",
                                            width: "150px",
                                            margin: "4px",
                                            marginLeft: "250px",
                                            backgroundSize: "fixed",
                                          }}
                                        />
                                      ) : (
                                        formData?.image && (
                                          <img
                                            src={IMG_URL + formData.image}
                                            alt={formData?.image || "Image"}
                                            className="table-image"
                                            style={{
                                              height: "100px",
                                              width: "150px",
                                              marginTop: "4px",
                                              marginLeft: "200px",
                                              backgroundSize: "fixed",
                                            }}
                                          />
                                        )
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="main-form-section mt-4">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Linked In URL:
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="linkedin_url"
                                            value={formData?.linkedin_url}
                                            onChange={handleChange}
                                            placeholder="Linked In URL"
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        {errors?.linkedin_url && (
                                          <span style={errorStyle}>
                                            {errors?.linkedin_url}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              {/* </Row> */}

                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>
                              {/* </Col> */}
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
