import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get("gt_security");

const baseURL = process.env.REACT_APP_BASE_URL;

const axiosClient = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  },
});

axiosClient.interceptors.request.use(
  async (config) => {
    try {
      const token = Cookies.get("gt_security");

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      } else {
        config.headers["Authorization"] = "";
      }

      return config;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);

export default axiosClient;
