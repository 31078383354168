import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import pen from "../../../../Components/Admin/assets/icons/pen.png";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import top from "../../../../Components/Admin/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../../common/ModalDelete";
import { AddButton, EditButton, DeletButton } from "../../../common/Button";
import html2pdf from "html2pdf.js";
import { useParams } from "react-router-dom";
import firstImage from "./images/firstImage.jpg";
import mainImage from "./images/WhatsApp.jpeg";
// import mainImage from "./images/mainImage.png";

import QRCode from "qrcode.react";
import * as XLSX from "xlsx";
import { App } from "./GeneratePDF";

library.add(fas);

const Tables = () => {
  const {
    postData,
    getData,
    IMG_URL,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
    getDownloadDataExcel,
    getDownloadDataPDF,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [dataPDF, setDataPDF] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();

  const [changeStatus, setChangeStatus] = useState();

  const getDataAll = async () => {
    const response = await getData(
      `/masters/users?page=${currentPage}&per_page=${perPage}&term=${search}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };
  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/masters/users/${id}`);
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/masters/users/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const downloadQRCode = (contact) => {
    // Create a link element and trigger a download
    const a = document.createElement("a");
    a.href = contact;
    a.download = "contactQRCode.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const generatePdf = async (id, name) => {
    const res = await getDownloadDataPDF(
      `/masters/users/download-user/${id}`,
      name
    );
  };

  //   const generatePdf = async (id) => {
  //     try {
  //       const response = await getData(`/masters/users/${id}`);

  //       const dataPDF = response.data;

  //       const content = `
  //     <!DOCTYPE html>
  // <html lang="en">

  // <head>
  //     <meta charset="UTF-8">
  //     <meta name="viewport" content="width=device-width, initial-scale=1.0">
  //     <title>Document</title>

  //     <style>
  //         .GTMain {
  //           display: flex;
  //           justify-content: center;
  //           flex-direction: column;
  //           align-items: center;
  //       }
  //         .GT {
  //             border: 1px solid #000;
  //             width: 400px;
  //             height: 265px;
  //         }

  //         .GT p {
  //             font-weight: 600;
  //             font-size: 12px;
  //             /* padding: 0px; */
  //             margin-bottom: 2px;
  //             margin-left: 9px;
  //         }

  //         .GT .logo_main {
  //             /* border: 1px solid #000; */
  //             overflow: hidden;
  //         }

  //         .GT .logo_main .first {
  //             width: 450px;
  //             /* height: 200px; */
  //             float: left;
  //         }

  //         .GT .logo_main .first .img_logo {
  //             width: 172px;
  //             /* height: 100%; */
  //             object-fit: cover;
  //         }

  //         .GT .logo_main .second {
  //             /* width: 111px; */
  //             height: 83px;
  //             float: right;
  //             margin-top: -14%;
  //             margin-right: 5%;
  //         }

  //         .GT .logo_main .second .img_qr {
  //             width: 85px;
  //             height: 85px;
  //             object-fit: cover;
  //         }

  //         .GT .card-image-class {
  //             margin-top: 1%;
  //             width: 100%;
  //             /* height: 249px; */
  //             text-align: center;
  //         }

  //         .GT .card-image-class .gt-logo {
  //             width: 165px;
  //             /* height: 110px; */
  //             object-fit: cover;
  //         }

  //         .GT .content {
  //             width: 100%;
  //             /* height: 500px; */
  //             display: flex;
  //         }

  //         .GT .content .name-info {
  //             width: 50%;
  //             height: 150px;
  //             position: relative;
  //         }

  //         .GT .content .name-info div {

  //             font-weight: bolder;
  //         }

  //         .GT .content .name-info .bottom-content {
  //             position: absolute;
  //             bottom: 25px;
  //         }

  //         .GT .content .adress-info {
  //              width: 50%;
  //             height: 133px;
  //             margin-right: 2%;
  //         }

  //         .GT .content .adress-info .add_text {
  //             text-align: right;
  //             margin-bottom: 5px;
  //            font-size: 12px;
  //         }

  //         .GTMain .imaegSeecMIn {
  //           border: 1px solid #000;
  //           width: 400px;
  //           height: 265px;
  //           margin-bottom: 10px;
  //           text-align: center;
  //           display: flex;
  //           justify-content: center;
  //       }

  //       .GTMain  .imgMainlogo {
  //           width: 300px;
  //           height:200px;
  //           object-fit: contain;
  //           margin: auto;
  //       }

  //     </style>
  // </head>

  // <body>
  // <section class="GTMain">
  //     <div class="imaegSeecMIn">
  //       <img alt="main-img" src=${mainImage}  class="imgMainlogo"/>
  //     </div>

  //     <div class="GT">
  //         <div class="logo_main">
  //             <!-- <div class="card-image-class">
  //                 <img class="gt-logo" alt="main-img" src=${mainImage} />
  //             </div> -->

  //             <div class="logo_main">
  //                 <div class="first">
  //                     <img class="img_logo" src=${firstImage} alt="first-img" />
  //                 </div>

  //                 <div class="second">
  //                     <img class="img_qr" src=${dataPDF.user_scanner.image} />
  //                 </div>
  //             </div>

  //             <div class="content">
  //                 <div class="name-info">
  //                     <div>
  //                         <p style="color: blue;">${dataPDF.name} ${dataPDF.last_name}</p>
  //                         <p>${dataPDF.position}</p>
  //                         <p>${dataPDF.email} </p>
  //                     </div>

  //                 </div>

  //                 <div class="adress-info">
  //                     <p class="add_text ms-0 me-0 mb-0">3rd Floor, Lugogo One</p>
  //                     <p class="add_text  m-0">Plot 23, Lugogo Bypass,</p>
  //                     <p class="add_text  m-0">P.O.Box 7158,</p>
  //                     <p class="add_text ">Kampala, Uganda,</p>
  //                     <p class="add_text  m-0">T:${dataPDF?.mobile_number},</p>
  //                     <p class="add_text m-0">D:+256 200 807| Ext. ${dataPDF?.address},</p>
  //                     <p class="add_text m-0"><a href="http://www.gtuganda.co.ug" target="_blank">www.gtuganda.co.ug</a></p>
  //                 </div>
  //             </div>
  //             <div class="bottom-content">
  //             <p>Member of Grant Thornton International</p>
  //         </div>
  //         </div>
  //     </div>

  //     </section>
  // </body>

  // </html>`;

  //       // +256 200 807 600

  //       const options = {
  //         margin: 10,
  //         filename: `${dataPDF.name} ${dataPDF.last_name} Card.pdf`,
  //         image: { type: "jpeg", quality: 0.98 },
  //         output: "save",
  //       };

  //       html2pdf().from(content).set(options).save();
  //     } catch (error) {
  //       console.error("Error fetching or generating PDF:", error);
  //     }
  //   };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Users"} link={"/masters/users"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <section className="Tabels tab-radio tab-radio mt-5">
              <div className="">
                <div className="row">
                  <div className="d-flex">
                    <div className="add me-3">
                      {isAllow.includes(14) ? (
                        <Link type="button" className="btn btn-add pe-3">
                          <div onClick={() => handleShow()}>
                            <img src={plus} className="plus me-2 ms-0" alt="" />
                            Add User
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="add">
                      <div className="dropdown">
                        <button
                          className="btn btn-columns dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={colunms} className="columns " alt="" />
                          Column Selection{" "}
                          <img src={top} className="top ms-1" alt="" />{" "}
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col1")}
                              href="#"
                            >
                              Sr. No.
                              {visible?.col1 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col2")}
                              href="#"
                            >
                              Name
                              {visible?.col2 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col3")}
                              href="#"
                            >
                              Position
                              {visible?.col3 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col4")}
                              href="#"
                            >
                              Email
                              {visible?.col4 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col5")}
                              href="#"
                            >
                              Address
                              {visible?.col5 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col6")}
                              href="#"
                            >
                              QRDownload
                              {visible?.col6 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col7")}
                              href="#"
                            >
                              Download
                              {visible?.col7 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col8")}
                              href="#"
                            >
                              Status
                              {visible?.col8 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col9")}
                              href="#"
                            >
                              Action
                              {visible?.col9 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-4">
                    <div className="  col-lg-4 col-md-4 col-12">
                      <div className="d-flex">
                        <div className="show me-2">
                          <p className="show mt-1">Show</p>
                        </div>
                        <div className="number me-2">
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            onChange={(e) => setperPage(e.target.value)}
                          >
                            {option?.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="entries">
                          <p className="show mt-1">entries</p>
                        </div>
                      </div>
                    </div>
                    <div className="  col-lg-8  col-md-8 col-12">
                      <div className="d-flex justify-content-end">
                        <div className="sowing me-2">
                          <p className="show mt-2">{`Showing ${Math.min(
                            (currentPage - 1) * perPage + 1
                          )} to ${Math.min(
                            currentPage * perPage,
                            data?.data?.totalEntries
                          )} of ${data?.data?.totalEntries} entries`}</p>
                        </div>
                        <div className="num me-2">
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                        <div className="Search me-2">
                          <button
                            type="button"
                            onClick={getDataAll}
                            className="btn btn-search"
                          >
                            <img src={search1} className="search" alt="" />
                          </button>
                        </div>

                        <div className="Search-1">
                          <button
                            type="button"
                            onClick={() => {
                              setSearch("");
                              setReset(!reset);
                            }}
                            className="btn btn-reset"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-line mt-3"></div>
                <div className="row mt-3">
                  <div className="data table-responsive">
                    <Table striped bordered hover responsive center>
                      <thead>
                        <tr className="">
                          {visible.col1 && <th className="sr">Sr. No.</th>}
                          {visible.col2 && <th className="tax-name">Name</th>}
                          {visible.col3 && (
                            <th className="tax-name">Position</th>
                          )}
                          {visible.col4 && <th className="tax-name">Email</th>}
                          {visible.col5 && <th className="tax-name">Image</th>}
                          {visible.col6 && (
                            <th className="tax-name">QR Download</th>
                          )}
                          {visible.col7 && (
                            <th className="tax-name">Download</th>
                          )}
                          {visible.col8 && <th className="tax-name">Status</th>}

                          {visible.col9 && <th className="active">Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {isAllow.includes(13) ? (
                          data?.data?.data?.map((d, index) => {
                            return (
                              <tr className="" key={index}>
                                {visible.col1 && <td>{++index}.</td>}
                                {/* {visible.col2 && <td>{d?.country?.name}</td>} */}
                                {visible.col2 && (
                                  <td>{`${d?.name}  ${d?.last_name}`}</td>
                                )}
                                {visible.col3 && <td>{d?.position}</td>}
                                {visible.col4 && <td>{d?.email}</td>}
                                {/* {visible.col5 && <td>{d?.address}</td>} */}
                                {/* {visible.col6 && <td>{d?.userScanner?.image}</td>} */}
                                {visible.col5 && (
                                  <td>
                                    {d.image && (
                                      <img
                                        src={IMG_URL + d.image}
                                        alt={d?.image || "image"}
                                        className="table-image"
                                        style={{
                                          height: "75px",
                                          width: "110px",
                                        }}
                                      />
                                    )}
                                  </td>
                                )}

                                {visible.col6 && (
                                  <td>
                                    {d?.user_scanner?.image && (
                                      // <img
                                      //   src={d?.userScanner?.image}

                                      //   alt={d?.userScanner?.image || "Image"}
                                      //   className="table-image"
                                      //   style={{ height: '75px', width: '110px' }}
                                      // />
                                      <Button
                                        onClick={() =>
                                          downloadQRCode(d?.user_scanner?.image)
                                        }
                                        type="button"
                                        className="btn btn-secondary me-1"
                                      >
                                        QRDownload
                                      </Button>
                                    )}
                                  </td>
                                )}

                                {visible.col7 && (
                                  <td>
                                    {d && (
                                      <Button
                                        // onClick={() => ExcelhandleDownload(d?.id)}
                                        onClick={() =>
                                          generatePdf(
                                            d?.id,
                                            d?.name + " " + d?.last_name
                                          )
                                        }
                                        type="button"
                                        className="btn btn-secondary me-1"
                                      >
                                        Download
                                      </Button>
                                    )}
                                  </td>
                                )}

                                {visible.col8 && (
                                  <td>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={d.status}
                                        onChange={() => {
                                          ChangeStatus(d.id);
                                        }}
                                        id={`flexSwitchCheckDefault${d?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                      >
                                        {d.status ? "Active" : "Inactive"}
                                      </label>
                                    </div>
                                  </td>
                                )}
                                {visible.col9 && (
                                  <td>
                                    <div className="d-flex">
                                      {isAllow.includes(15) ? (
                                        <Button
                                          onClick={() => handleShow1(d?.id)}
                                          type="button"
                                          className="btn btn-primary me-1"
                                        >
                                          <img
                                            src={pen}
                                            className="pen"
                                            alt=""
                                          />
                                        </Button>
                                      ) : (
                                        <></>
                                      )}

                                      {isAllow.includes(16) ? (
                                        <DeletButton
                                          showDeleteRecord={showDeleteRecord}
                                          id={d?.id}
                                          name={d?.name}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}
      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
