import axiosClient from "./ApiInstance";
import Cookies from "js-cookie";
import axios from "axios";

export const getData = async (url, data) => {
  try {
    const response = await axiosClient.get(
      process.env.REACT_APP_BASE_URL + url,
      data
    );
    return response.data;
  } catch (error) {
    // Cookies.remove("gt_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const postData = async (url, data) => {
  try {
    const response = await axiosClient.post(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("gt_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const editStatusData = async (url, data = {}) => {
  try {
    const response = await axiosClient.put(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("gt_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const deleteData = async (url) => {
  try {
    const response = await axiosClient.delete(
      process.env.REACT_APP_BASE_URL + url
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("gt_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

// Download Apis
export const getDownloadDataExcel = async (aurl, data, name) => {
  try {
    const response = await axiosClient.post(
      process.env.REACT_APP_BASE_URL + aurl,
      data,
      {
        responseType: "blob", // Set responseType to "blob"
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${name + " " + new Date().toLocaleDateString()}.xlsx`
    );
    document.body.appendChild(link);
    link.click();

    // Clean up after the download
    window.URL.revokeObjectURL(url);
  } catch (error) {
    // Cookies.remove("gt_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

// export const getDownloadDataPDF = async (aurl, name) => {
//   try {
//     const token = Cookies.get("gt_security");
//     // Make a request to download the PDF with the headers
//     const headers = {
//       Authorization: `Bearer ${token}`,
//     };
//     const downloadUrl = process.env.REACT_APP_BASE_URL + aurl;
//     const response = await axios.get(downloadUrl, {
//       responseType: "arraybuffer",
//       headers,
//     });

//     // Convert the response data to a Blob
//     const blob = new Blob([response.data], { type: "application/pdf" });
//     console.log(blob);
//     // Create a download link
//     const url = window.URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = `${name}.pdf`; // Adjust the filename as needed
//     document.body.appendChild(a);

//     // Trigger the download
//     a.click();

//     // Clean up
//     window.URL.revokeObjectURL(url);
//   } catch (error) {
//     // Handle errors here
//     console.error("Failed to download PDF. Error: ", error);
//   }
// };

export const getDownloadDataPDF = async (aurl, name) => {
  try {
    // Retrieve the authentication token from cookies
    const token = Cookies.get("gt_security");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const downloadUrl = process.env.REACT_APP_BASE_URL + aurl;

    const response = await axios.get(downloadUrl, {
      responseType: "arraybuffer",
      headers,
    });

    const blob = new Blob([response.data], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${name}.pdf`;
    document.body.appendChild(a);

    a.click();

    window.URL.revokeObjectURL(url);

    document.body.removeChild(a);
  } catch (error) {
    // Handle errors here
    console.error("Failed to download PDF. Error: ", error);
  }
};
