import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AdvanceDashboard.css";
import Header from "../../Header/Header";
import Piechart from "../Piechart/Piechart";
import Barchart from "../Barchart/Barchart";
import LineChart from "../LineChart/LineChart";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas);
function AdvanceDashboard() {
  const [startDate, setStartDate] = useState(new Date());

  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  const toggleDatePicker = () => {
    setDatePickerOpen(!isDatePickerOpen);
  };
  return (
    <div className="main-advancedashboard">
      <Header title={"Dashboard"} link={"/"} />
    
    </div>
  );
}

export default AdvanceDashboard;
